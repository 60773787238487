<template>
  <div class="popup-gallery d-flex flex-wrap">
    <div
      v-show="mostrarImagenes"
      v-for="(src, index) in articulo_fotos"
      :key="index"
      class="float-left"
      @click="() => showCaptiomImg(index)"
    >
      <img :src="src.src" width="275" />
    </div>
    <vue-easy-lightbox
      :visible="visibleCaption"
      :imgs="articulo_fotos"
      :index="index"
      @hide="handleCaptionHide"
    ></vue-easy-lightbox>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";

import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
import API from '@/API.js'

export default {
  name: 'CmpGaleriaFotos',
  components: { VueEasyLightbox },
  props: {
    nombreGaleria: {
      type: String,
      required: true,
    },
    mostrarImagenes: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      articulo:{
        id: null,
        nombre: ''
      },
      index: 0, // default: 0
      visibleCaption: false,
      articulo_fotos: []
    }
  },
  methods: {
    showCaptiomImg(index) {
      this.index = index;
      this.visibleCaption = true;
    },
    handleCaptionHide() {
      this.visibleCaption = false;
    },

    cargarFotosArticulo: function(id_articulo) {
      var self = this

      ArticuloSrv.fotosJSON(id_articulo).then(response => {
        let fotos = response.data
        console.log(fotos)

        fotos.forEach(foto => {
          self.articulo_fotos.push({
            //src: `${self.API}/imagenes/${foto.id}?tipo_foto=original&ruta=productos&_tk=${self.tk}&tmp=${Math.random().toFixed(10).substring(2)}`,
            src: `${self.API}/imagenes/${foto.id}?tipo_foto=original&ruta=productos&_tk=${self.tk}`,
            title:`Artículo: ${self.articulo.nombre}, Foto: ${foto.id}`,
          })
        })
        
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las fotos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.showCaptiomImg(0)
      })
    },
    mostrarGaleria(id_articulo = null) {
      let self = this

      self.articulo= {}
      self.articulo_fotos = []

      ArticuloSrv.articuloJSON(id_articulo).then(response => {
        self.articulo = response.data
        self.cargarFotosArticulo(self.articulo.id)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el artículo '+idArticulo
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>