<template>
  <Layout tituloPagina="Salidas | Nueva salida de articulos">
    <div class="row">
      <div class="col-lg-9 col-md-11 col-sm-12">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h5 class="card-title">
              {{ modo == 'nuevo' ? 'Nueva salida' : 'Visor de salida' }} de artículos
            </h5>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="guardar()"
                      v-if="!bandera_spinner && modo == 'nueva'"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      Dar salida
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      Dar salida
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="cerrar()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row" v-show="modo=='visor'">
              <div class="col-md-3">
                <label for="idSalida">ID de salida del almacén</label>
                <input name="idSalida" type="text" 
                  class="form-control text-right" v-model="salida.id_salalm"
                  readonly>
              </div>
              <div class="col-md-offset-1 col-md-4">
                <label for="nombreUsuario">Almacenista que registró la salida</label>
                <input type="text" v-model="salida.nombre_almacenista" 
                  class="form-control"
                  readonly>
              </div>
              <div class="col-md-4">
                <label for="nombreUsuario">Solicitante de los artículos</label>
                <input type="text" v-model="salida.nombre_solicitante" 
                  class="form-control"
                  readonly>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label for="tipo">Tipo de salida</label>
                <select name="tipo" v-model="salida.tipo" class="form-control" disabled>
                  <option value="1">Salida sin solicitud de artículos</option>
                  <option value="3">Salida por conversión de artículos</option>
                  <option value="4">Salida por solicitud de artículos</option>
                </select>
              </div>
              <div class="col-md-8">
                <label for="notaSalida">Nota</label>
                <textarea name="notaSalida" v-model="salida.nota" class="form-control"
                  :readonly="modo=='visor'"></textarea>
              </div>
            </div>
            <br>
            <div class="text-right">
              <button @click="mostrarMdlNuevaPartida()" class="btn btn-success btn-sm"
                v-show="modo=='nueva'">
                <i class="mdi mdi-plus-thick"></i>
                Nueva partida
              </button>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive table-nowrap">
                  <table class="table table-hover" style="min-width: 1200px">
                    <thead>
                      <tr>
                        <th style="width:40px"></th>
                        <th style="width:40px"></th>
                        <th style="width: 50px">#</th>
                        <th style="width: 75px">Cantidad</th>
                        <th style="width: 90px">Unidad de medida</th>
                        <th>Nombre del artículo</th>
                        <th>División</th>
                        <th style="width: 90px">Familia de artículo</th>
                        <th>Descripción del artículo</th>
                        <th style="width:100px;" class="text-center" v-show="modo == 'nueva'">
                          Acción
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(partida,index) in salida.partidas">
                        <tr v-if="true" :key="`partida-${index+1}`">
                          <td>
                            <button
                              v-show="partida.id_articulo_original != null"
                              class="btn btn-default btn-xs"
                              @click="palancaMostrarArticuloOriginal(partida)"
                            >
                              <i
                                class="fa fa-angle-down"
                                v-show="partida.mostrar_articulo_original == false"
                              ></i>
                              <i
                                class="fa fa-angle-up"
                                v-show="partida.mostrar_articulo_original == true"
                              ></i>
                            </button>
                          </td>
                          <td>
                            <div style="min-height: 30px; display: inline-block">
                              <a
                                href="#"
                                onclick="return false;"
                                @click="mostarGaleriaFotosArticulo(partida.id_articulo)"
                                v-if="partida.foto_principal"
                              >
                                <img
                                  :src="`${API}/articulos/${partida.id_articulo}/fotos/principal?tipo_foto=64x64&tmp=${Math.random().toFixed(10).substring(2)}&_tk=${tk}`"
                                  style="width:30px; height:30px;"
                                  class="icon-articulo"
                                />
                              </a>
                              <img
                                :src="inventarioIcon"
                                style="width:30px; height:30px;"
                                v-if="!partida.foto_principal"
                              />
                            </div>
                          </td>
                          <td>{{index+1}}</td>
                          <td class="text-right">{{partida.cantidad}}</td>
                          <td>{{partida.abreviatura_unidad_medida}}</td>
                          <td>{{partida.nombre_articulo}}</td>
                          <td>{{partida.division}}</td>
                          <td class="text-right">{{partida.familia}}</td>
                          <td>{{partida.descripcion_articulo}}</td>
                          <td class="text-center" v-show="modo == 'nueva'">
                            <button class="btn btn-danger btn-sm"
                              @click="eliminarPartida(index)">
                              <i class="mdi mdi-trash-can-outline"></i>
                              Eliminar
                            </button>
                          </td>
                        </tr>
                        <tr v-if="partida.mostrar_articulo_original" :key="'partida_articulo-original-'+partida.id" class="bg-success sin-hover">
                          <td colspan="6" class="text-left">
                            <strong>Articulo remplazado: </strong>
                            <div style="min-height: 30px; display: inline-block">
                              <a
                                href="#"
                                onclick="return false;"
                                @click="mostarGaleriaFotosArticulo(partida.articulo_original.id)"
                                v-if="partida.articulo_original.foto_principal"
                              >
                                <img
                                  :src="`${API}/articulos/${partida.articulo_original.id}/fotos/principal?tipo_foto=64x64&tmp=${Math.random().toFixed(10).substring(2)}&_tk=${tk}`"
                                  style="width:30px; height:30px;"
                                  class="icon-articulo"
                                />
                              </a>
                              <img
                                :src="inventarioIcon"
                                style="width:30px; height:30px;"
                                v-if="!partida.articulo_original.foto_principal"
                                class="img-thumbnail"
                              />
                            </div>
                            {{partida.articulo_original.nombre}}
                            <br>
                            <strong>ID articulo: </strong>{{partida.articulo_original.id}}
                          </td>
                          <td colspan="3" class="text-left">
                            <strong>Descripción artículo: </strong>
                            {{partida.articulo_original.descripcion}}
                            <br><br>
                            <strong>División: </strong>{{partida.articulo_original.division}}
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <div class="btn-group">
            <button class="btn btn-secondary" @click="cerrar()">
              <i class="mdi mdi-chevron-left"></i>
              Atras
            </button>
            <button
              class="btn btn-success"
              @click="guardar()"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              Dar salida
            </button>
          </div>
        </div>
      </div>
    </div>

    <MdlNuevaPartidaSalida
      ref="mdlNuevaPartidaSalida"
      v-on:partida:lista="agregarPartida($event)"
    />
    <cmp-galeria-fotos 
      ref="galeria_salidas_articulo"
      :data_gallery="`galeria-salidas-articulo`"
    />
    <br>
  </Layout>
</template>

<script>
import SalidaSrv from '@/services/inventario/SalidaSrv.js'
import MdlNuevaPartidaSalida from './MdlNuevaPartidaSalida.vue'
import inventarioIcon from '@/assets/img/inventario/inventario.png'
import CmpGaleriaFotos from "../CmpGaleriaFotos.vue"
import Layout from "@/views/layouts/main.vue"
import { APIINV as API } from '@/API.js'

export default {
  name: 'SalidaAlmacen',
  components: {
    MdlNuevaPartidaSalida,
    Layout,
    CmpGaleriaFotos
  },
  data: function () {
    return {
      modo: 'nueva',
      API : API,
      tk: localStorage.getItem('argusblack.token'),
      inventarioIcon: inventarioIcon,
      salida: {
        id: null,
        id_solalm: null,
        tipo: 1,      // 1 = Salida, 2 = (Sin definir), 3 = Salida por conversión de artículos, 4 = Salida por solicitud de artículos
        nota: '',

        /**
         * Cada partida tiene la siguiente estructura
         * 
         * partidas: [
         *    {
         *      id_articulo: int,
         *      cantidad: float,
         *      id_unidad_medida: int,
         *      id_partida_reqart: int
         *    },
         *    ...
         * ]
         */
        partidas: []
      },

      bandera_spinner: false
    }
  },
  created: function() {
    var self = this
    
    if(self.$route.params.id != undefined) {
      self.salida.id = self.$route.params.id
      self.modo = 'visor'
      
      self.cargarSalida()
    }
  },
  methods: {
    atras() {
      this.$router.go(-1)
    },

    agregarPartida(nuevaPartida) {
      var self = this

      let index = self.salida.partidas.findIndex(function (partida) {
        return partida.id_articulo == nuevaPartida.id_articulo
      })
      
      if(index != -1) {
        iu.msg.warning('No se puede registrar una partida con el mismo artículo')
        return
      }

      self.salida.partidas.push(nuevaPartida)
    },

    cargarSalida() {
      let self = this
      let idSalida = this.$route.params.id

      SalidaSrv.salidaJSON(idSalida).then(response => {
        let salida = response.data
        
        salida.partidas.map(partida => {
          partida.mostrar_articulo_original = false

          return partida
        })

        self.salida = salida
      }).catch(error => {
        let mensaje 
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la salida'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },

    eliminarPartida: function(index) {
      this.salida.partidas.splice(index, 1)
    },

    mostarGaleriaFotosArticulo: function(idArticulo) {
      var self = this
      self.$refs.galeria_salidas_articulo.mostrarGaleria(idArticulo)
    },

    mostrarMdlNuevaPartida() {
      this.$refs.mdlNuevaPartidaSalida.mostrar()
    },

    palancaMostrarArticuloOriginal: function(partida) {
      if (partida.mostrar_articulo_original) partida.mostrar_articulo_original = false
      else partida.mostrar_articulo_original = true
    },

    guardar(){
      var self = this

       self.bandera_spinner = true

      if(self.salida.partidas.length == 0) {
        iu.msg.warning('No se puede realizar la salida de artículos, ya que no se ha seleccionado ningún artículo')
        self.bandera_spinner = false
        return
      }

      let newSalida = {
        id: self.salida.id,
        id_solalm: self.salida.id_solalm,
        tipo: self.salida.tipo,
        nota: self.salida.nota,
        partidas: []
      }

      self.salida.partidas.forEach(partida => {
        let newPartida = {
          id_articulo: partida.id_articulo,
          cantidad: partida.cantidad,
          id_partida_reqart: partida.id_partida_reqart,
          id_familia: partida.id_familia
        }
        newSalida.partidas.push(newPartida)
      })
      
      SalidaSrv.guardar(newSalida).then(response => {
        iu.msg.success('Se guardó correctamente la salida')
        self.$router.go(-1)
      }).catch(error => {
        let mensaje 
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo realizar la salida'
        }
        iu.msg.warning(mensaje)
        console.log(error)
      }).finally(fin => {
        self.bandera_spinner = false
      })
    }
  }
}
</script>

<style>
.table-hover > tbody > tr.sin-hover:hover {
  background-color: #1C84C6;
}
.icon-articulo {
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
</style>
